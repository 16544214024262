<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Entry -->
        <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <div class="col col-lg-auto">
              <b-button
                variant="success"
                @click="ExportExcel"
              > 
                <feather-icon icon="DownloadIcon" /> {{ $t('globalActions.excel') }}
              </b-button>
          </div>
          <div class="col col-lg-auto">
            <b-button
              variant="primary"
              :to="{ name: 'apps-master-data-product-add'}"
            >
              <feather-icon icon="PlusIcon" /> {{ $t('apps.masterDataProduct.actions.create') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-included-fields="columnToBeFilter"
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :busy="isBusy"
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <!-- Column: code -->
      <template #cell(code)="data">
        <b-link
          :to="{ name: 'apps-master-data-product-detail', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          ( {{ data.item.code }} )  {{ data.item.name}}
        </b-link>
      </template>
    
      <!-- Column: Type -->
      <template #cell(productType)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.product_type }} 
        </span>
      </template>
      
      <!-- Column: Brand -->
      <template #cell(brandId)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.brand !== null ? data.item.brand.name: '' }}
        </span>
      </template>
      
      <!-- Column: Category -->
      <template #cell(categoryId)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.category.name }}
        </span>
      </template>

      <!-- Column: Audit -->
      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
        {{ item.updated_process }}  By {{ item.getUser.user_name }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>


    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refInvoiceListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
  BSpinner
} from 'bootstrap-vue'

// import useList from './useList'
import useListTable from '@/comp-functions/useListTable'
import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BSpinner
  },
  setup () {
    const { $t } = useLocalization()
    // Table Handlers
    const tableColumns = [
      { key: 'code', label: 'Product', sortable: true },
      { key: 'productType', label: 'Type' },
      { key: 'brandId', label: 'Brand' },
      { key: 'categoryId', label: 'Category' },
      { key: 'audit', label: $t('globalSingular.audit') }
    ]
    
    // Table Handlers
    const columnToBeFilter = [
      'code',
      'name',
      'description',
      'brand'
    ]

    return {
      tableColumns,
      columnToBeFilter,
      ...useListTable({
        url: 'master/product'
      })
    }
  },
    methods: {
    ExportExcel () {
       import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists
        for (let index = 0; index < list.length; index++) {
          list[index].audit = list[index].updated_process + ' By ' + list[index].getUser.user_name + ' Date ' + list[index].updated_at
          list[index].code = list[index].code + '-' + list[index].name
          list[index].productType = list[index].product_type
          list[index].brand = list[index].brand !== null ?  list[index].brand.name : ''
          list[index].categoryId = list[index].category.name
        }

        const date = new Date();
        
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
      
        let currentDate = `${day}-${month}-${year}`;

        const headerTitle = this.tableColumns.map(column => column.label)

        const headerVal = this.tableColumns.map(column => column.key)

        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            ['Master Data', '', '', '', '', '', ''],
            ['Product', '', '', '', '', '', ''],
            [currentDate, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Master data Product`,
          autoWidth: true,
          bookType: 'xlsx'
        })

      })

    },
        formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>
